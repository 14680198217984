<template>
  <div class="bg-white flex flex-col md:flex-row">
    <!-- Left side with the logo and login form -->
    <div class="md:w-1/2 flex flex-col items-center md:p-8 mt-auto md:mt-0 relative">
      <!-- Logo -->
      <a href="https://www.fluitendleren.nl">
        <img src="../../assets/logo_transparant.png" alt="Fluitend Leren Logo"
             class="mb-4 sm:mb-8 md:ml-4 sm:absolute sm:top-0 sm:left-0 sm:mt-4">
      </a>
      <div class="md:w-1/2 md:p-0 w-full p-8 items-center my-auto">
        <h1 class="text-xl mb-4 text-center">Welkom bij Fluitend Leren</h1>
        <h2 class="text-2xl mb-8 text-center">Inloggen</h2>

        <form @submit.prevent="handleLogin()" class="flex flex-col">

          <input-component v-model="useAuthStore.form.email" input-type="email" placeholder="email" inputColor="primary"
                           class="w-full mb-4" :required="true"/>
          <input-component v-model="useAuthStore.form.password" input-type="password" placeholder="password"
                           inputColor="secondary" class="w-full" :required="true"/>

          <div class="text-red-500 mb-4" v-if="notification.message">{{ notification.message }}. <span
              @click="useAppStore.showConsentAgain()"
              class="underline text-black hover:text-FLPrimary hover:cursor-pointer">Aanpassen</span></div>

          <div id="recaptcha" class="g-recaptcha"></div>
          <div class="text-red-500 mb-4" v-if="notification.recaptchaMessage">{{ $t(notification.recaptchaMessage).capitalize() }}.</div>

          <button-component type="submit" class="shadow-xl mx-auto w-full md:w-full my-4" buttonText="login"
                            :is-loading="useAuthStore.isLoading" buttonColor="primary" :always-show-text="true"/>

        </form>

        <RouterLink to="/register" class="w-full">
          <button-component type="button" class="shadow-xl mx-auto w-full md:w-full opacity-100 hover:opacity-50 mb-4"
                            buttonText="try our demo!" :disabled="useAuthStore.isLoading" buttonColor="secondary"
                            :always-show-text="true"/>
        </RouterLink>

        <RouterLink to="/recover" class="text-center block mx-auto mb-4 text-gray-500">
          {{ $t('forgot password').capitalize() }}?
        </RouterLink>
      </div>

      <!-- Privacy Policy and Terms of Service Links -->
      <div class="absolute bottom-4 left-4 flex space-x-4">
        <button @click="showPrivacyPolicy = true" class="text-gray-500 underline">Privacybeleid</button>
        <button @click="showTermsOfService = true" class="text-gray-500 underline">Algemene Voorwaarden</button>
      </div>
    </div>

    <!-- Right side with the image -->
    <div class="hidden sm:block md:w-1/2">
      <img src="../../assets/Tumbnail-Inlogomgeving.png" alt="Girl Holding Book" class="w-full h-full object-cover">
    </div>

    <!-- Privacy Policy Popup -->
    <privacy-policy v-if="showPrivacyPolicy" @close="showPrivacyPolicy = false"/>

    <!-- Terms of Service Popup -->
    <terms-of-service v-if="showTermsOfService" @close="showTermsOfService = false"/>

  </div>
</template>
<script>
import {ref, onMounted} from 'vue';
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import InputComponent from "@/components/forms/InputComponent.vue";
import {authStore} from "@/views/auth/stores/auth.store";
import {useRouter} from 'vue-router';
import {canUseCookies, hasDeniedCookies} from "@/auth.js";
import PrivacyPolicy from "@/views/auth/PrivacyPolicy.vue";
import TermsOfService from "@/views/auth/TermsOfService.vue";
import {i18n} from "@/i18n";
import {appStore} from "@/views/app.store";

export default {
  name: 'LoginComponent',


  setup() {
    const useAuthStore = authStore();
    const useAppStore = appStore();
    const router = useRouter();
    const notification = ref({
      message: '',
      type: 'error',
    });
    const showPrivacyPolicy = ref(false);
    const showTermsOfService = ref(false);

    const recaptchaVerified = ref(false);
    const recaptchaToken = ref('');
    const recaptchaWidgetId = ref(null);

    // Define the onRecaptchaVerified function here
    const onRecaptchaVerified = (token) => {
      recaptchaToken.value = token;
      recaptchaVerified.value = true;
    };

    // Reset and render reCAPTCHA
    const resetRecaptcha = () => {
      if (window.grecaptcha && recaptchaWidgetId.value !== null) {
        recaptchaVerified.value = false;
        window.grecaptcha.reset(recaptchaWidgetId.value);
      } else if (window.grecaptcha) {
        recaptchaWidgetId.value = window.grecaptcha.render('recaptcha', {
          'sitekey': '6Lfz1CsqAAAAAGwx8uo-2sd_Q-V0EU2TSNOrtPQJ',
          'callback': onRecaptchaVerified,
        });
      }
    };

    onMounted(() => {
      // Initialize or reset reCAPTCHA when the component is mounted
      resetRecaptcha();
    });

    return {
      useAppStore,
      useAuthStore,
      router,
      notification,
      recaptchaVerified,
      recaptchaToken,
      resetRecaptcha,
      showPrivacyPolicy,
      showTermsOfService,
    };
  },

  methods: {
    async handleLogin() {
      this.useAuthStore.isLoading = true;

      if (!this.recaptchaVerified) {
        this.notification.recaptchaMessage = 'please complete the reCAPTCHA';
        this.notification.type = 'error';
        this.useAuthStore.isLoading = false;
        this.resetRecaptcha();
        return;
      }

      if (canUseCookies()) {
        await this.useAuthStore.login(this.recaptchaToken).then(() => {
          this.router.push({name: 'Dashboard'});
        }).catch(error => {
          this.notification.message = error.response.data.message;
          this.notification.type = 'error';
        }).finally(() => {
          this.useAuthStore.isLoading = false;
          this.resetRecaptcha();
        });
      } else if (hasDeniedCookies()) {
        this.notification.message = i18n.t('you have denied cookies. To login, please enable cookies again').capitalize();
        this.notification.type = 'error';
        this.useAuthStore.isLoading = false;
        this.resetRecaptcha();
      } else {
        this.notification.message = i18n.t('you must accept cookies to log in').capitalize();
        this.notification.type = 'error';
        this.useAuthStore.isLoading = false;
        this.resetRecaptcha();
      }
    },
  },

  components: {InputComponent, ButtonComponent, PrivacyPolicy, TermsOfService}
}
</script>
