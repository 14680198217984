import { defineStore } from "pinia";
import authService from "@/views/auth/services/auth.service";
import { appStore } from "@/views/app.store";
import libraryService from "@/views/library/services/library.service";
import router from "@/router";
import { i18n } from '@/i18n';
import { canUseCookies, getEmailVerification, hasDeniedCookies } from "@/auth";
import { showSuccessAlert } from "@/helpers/sweetAlert";
import {libraryStore} from "@/views/library/stores/library.store";

export const authStore = defineStore('authStore', {
    state: () => ({
        emailVerify: {
            status: 'not_verified',
            password: '',
            password_confirmation: '',
            email: ''
        },
        form: {
            email: '',
            password: '',
        },
        formPasswordReset: {
            email: '',
            password: '',
            password_confirmation: '',
            token: '',
        },
        demoForm: {
            educator: 'n.v.t.',
            selectedDescribe: '',
            elseDescribe: '',
            selectedGrade: '',
            firstname: '',
            lastname: '',
            email: '',
            selectedSource: '',
            elseSource: '',
            selectedMath: '',
            elseMath: '',
            selectedLanguage: '',
            elseLanguage: '',
            reason: '',
            url: window.location.origin
        },
        permissions: [],
        user: {},
        role: {},
        translatedRole: '',
        isLoading: false,
        userIsEmailVerified: false,
        workspace: {}
    }),
    getters: {
        userAvatarUrl() {
            return "https://ui-avatars.com/api/?size=35&name=" + this.user.first_name + "+" + this.user.last_name + "&rounded=true&background=51AFE3&color=fff";
        },
    },
    actions: {
        async forgotPassword() {
            let form = {
                email: this.formPasswordReset.email
            }
            await authService.forgotPassword(form).then((response) => {
                let useAppStore = appStore();
                useAppStore.setError(response.data.message, 'success');
                router.push({ path: '/login' })
            }).catch((error) => {
                console.error("Error in sending password reset email:", error);
            });
        },
        async resetPassword() {
            let useAppStore = appStore();

            this.formPasswordReset.token = window.location.pathname.split('/').pop();
            await authService.resetPassword(this.formPasswordReset).then((response) => {
                useAppStore.setError(response.data.message, 'success');
                router.push({ path: '/login' })
            }).catch((error) => {
                console.error("Error in sending password reset email:", error);
            });
        },

        async checkEmail(){
            let token = window.location.pathname.split('/').pop();
            await authService.checkEmail(token).then((response) => {
                let status = response.data.data.status;
                this.emailVerify.status = response.data.data.status
                this.emailVerify.email = response.data.data.email

                if(status === 'email_not_verified_password_set'){
                    this.confirmEmail()
                    this.getProfile()
                    if(getEmailVerification()){
                        this.logout()
                    }
                }
            }).catch(() => {
                this.emailVerify.status = 'token_invalid';
            });
        },

        async confirmEmail() {
            let token = window.location.pathname.split('/').pop();
            let data = {
                token: token,
                password: this.emailVerify.password,
                password_confirmation: this.emailVerify.password_confirmation
            };
            await authService.confirmEmail(data).then(async (response) => {
                this.form.email = this.emailVerify.email
                this.form.password = this.emailVerify.password
                this.emailVerify.status = response.data.message

                if(this.form.email && this.form.password){
                    let useAppStore = appStore();
                    useAppStore.setError('email confirmed', 'success');
                    this.userIsEmailVerified = true
                    await this.login();
                    router.push({path: '/'})
                }
            }).catch((error) => {
                console.error("error in confirming email:", error);
            });
        },

        async sendEmailConfirm() {
            let useAppStore = appStore();
            let form = { email: this.form.email }

            await authService.sendEmailConfirm(form).then((response) => {
                useAppStore.setError(response.data.message, 'success');
            }).catch((error) => {
                console.error("Error in sending email confirmation:", error);
            });
        },

        async login() {
            if (canUseCookies()) {
                await authService.postLogin(this.form).then(response => {
                    if(response.data.user.email_verified_at !== null ){
                        this.userIsEmailVerified = true
                    } else {
                        this.userIsEmailVerified = false
                    }
                    const accessToken = response.data.access_token;
                    this.user = response.data.user;
                    this.workspace = response.data.workspace;

                    const user = JSON.stringify(response.data.user);
                    this.permissions = response.data.permissions;

                    const expiryDate = new Date();
                    expiryDate.setTime(expiryDate.getTime() + (24 * 60 * 60 * 1000));
                    const expires = "expires=" + expiryDate.toUTCString();

                    // Set cookies
                    if (response.data.workspace) {
                        document.cookie = "workspace=" + response.data.workspace + ";" + expires + ";path=/";
                    }
                    document.cookie = "accessToken=" + accessToken + ";" + expires + ";path=/";
                    document.cookie = "user=" + user + ";" + expires + ";path=/";

                    if(!this.user.email_verified_at){
                        return router.push({path: '/email/verify'})
                    }
                }).catch(error => {
                    console.error('Login failed', error);
                });
            } else if (hasDeniedCookies()) {
                alert('You have denied cookies. To login, please enable cookies again.');
            } else {
                alert('You must accept cookies to log in.');
            }
        },

        async setWorkspaceCookie(workspace) {
            const expiryDate = new Date();
            expiryDate.setTime(expiryDate.getTime() + (24 * 60 * 60 * 1000));
            const expires = "expires=" + expiryDate.toUTCString();

            document.cookie = "workspace=" + workspace + ";" + expires + ";path=/";
        },

        async logout(statusCode = null) {
            try {
                document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                document.cookie = 'user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                document.cookie = 'workspace=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

                let useLibraryStore = libraryStore();
                useLibraryStore.lastFetched = null
                useLibraryStore.pagination = {
                pagesLoaded: 0,
                    totalPages: 1,
                    currentPage: 1,
                    perPage: 12
                }

                useLibraryStore.books = []
                useLibraryStore.dashboard_books = []
                useLibraryStore.filters = []
                useLibraryStore.categories = []

                if (statusCode !== 401 && statusCode) {
                    await authService.postLogout();
                }

                router.push({ path: '/login' })
            } catch (error) {
                console.error('Logout failed:', error);
            }
        },

        async getProfile() {
            try {
                const response = await authService.getProfile();
                const userData = response.data.data;

                if(userData.user.email_verified_at !== null ){
                    this.userIsEmailVerified = true
                }
                this.user = userData?.user;
                this.permissions = userData?.permissions;
                if (userData?.workspace?.image_name && userData?.workspace?.image) {
                    await this.getWorkspaceLogoImage(userData?.workspace?.image_name);
                }


                this.workspace = userData?.workspace;
                this.role = userData?.user?.roles[0];
                this.translatedRole = i18n.t(userData?.user?.roles[0]?.name)
                document.title = this.workspace.name

            } catch (error) {
                console.error('Error in getting user details', error);
            }
        },

        async getWorkspaceLogoImage(image_name) {
            await libraryService.getBookImage(image_name).then(response => {
                let ImageBase64 = response.data.data;
                this.image = ImageBase64;
            });
        },

        base64ToBlob(base64) {
            const binaryString = window.atob(base64);
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return new Blob([bytes], { type: 'image/jpeg' });
        },

        async checkPermission(permission) {
            if (!this.permissions.length) {
                await this.getProfile();
            }
            return this.permissions.includes(permission);
        },

        async createDemoForm() {
            let apiForm = {
                "function": this.demoForm.selectedDescribe !== 'else' ? this.demoForm.selectedDescribe : this.demoForm.elseDescribe,
                "educator": this.demoForm.educator,
                "first_name": this.demoForm.firstname,
                "last_name": this.demoForm.lastname,
                "email": this.demoForm.email,
                "source": this.demoForm.selectedSource !== 'else' ? this.demoForm.selectedSource : this.demoForm.elseSource,
                "math_method": this.demoForm.selectedMath !== 'else' ? this.demoForm.selectedMath : this.demoForm.elseMath,
                "language_method": this.demoForm.selectedLanguage !== 'else' ? this.demoForm.selectedLanguage : this.demoForm.elseLanguage,
                "reason": this.demoForm.reason,
                "url": this.demoForm.url
            }

            try{
                await authService.postDemoForm(apiForm).then(async () => {
                    this.isLoading = false

                    showSuccessAlert('account created' , 'check your mailbox to verify your account')
                    router.push({ path: '/login' })
                    this.demoForm = {
                        educator: 'n.v.t.',
                        selectedDescribe: '',
                        elseDescribe: '',
                        selectedGrade: '',
                        firstname: '',
                        lastname: '',
                        email: '',
                        selectedSource: '',
                        elseSource: '',
                        selectedMath: '',
                        elseMath: '',
                        selectedLanguage: '',
                        elseLanguage: '',
                        reason: '',
                        url: window.location.origin
                    }
                }).catch(error => {
                    console.error('Registration failed', error);
                    this.isLoading = false
                })
            } catch (error) {
                console.error('Error in creating demo form', error);
                this.isLoading = false
            }
        }
    }
});
